<template>
  <div class="wrapper">
    <div class="content">
      <div class="matstyle">
        <el-button type="primary" class="title">申请材料递交</el-button>
        <div class="window-wrap">
          <p class="redPoint">温馨提示：</p>
          <p class="redPoint">1、选择“窗口递交”方式，需要您自行前往不动产登记中心提交纸质申请材料。</p>
          <p class="redPoint">
            2、选择“EMS上门收件”方式，在业务预审通过后，会自动根据您填写的信息下单邮政EMS上门取件服务，相关邮寄费用由申请人自行承担。
          </p>
        </div>
        <el-radio v-model="radio" label="1">窗口递交</el-radio>
        <el-radio v-model="radio" label="2">EMS上门收件</el-radio>
        <div class="window" v-if="radio == 1">
          <p>收取办理材料地址:</p>
          <p style="margin-top: 20px">{{ localAddress }}</p>
        </div>
        <div class="window" v-else>
          <el-form :rules="rules" :model="formGo" ref="formGo" :label-position="right" label-width="160px">
            <el-form-item label="收件大厅地址">
              {{ localAddress }}
            </el-form-item>
            <el-form-item prop="selectedOptionsGo" label="寄件人所在地">
              <el-cascader
                size="medium"
                :options="options"
                v-model="formGo.selectedOptionsGo"
                @change="handleChange(0)"
              ></el-cascader>
            </el-form-item>
            <el-form-item prop="address" label="寄件人详细地址">
              <el-input class="detail-ipt" v-model="formGo.address" placeholder="详细地址"></el-input>
            </el-form-item>
            <el-form-item prop="userName" label="联系人姓名">
              <el-input class="detail-ipt" v-model="formGo.userName" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
            <el-form-item prop="Tel" label="电话">
              <el-input class="detail-ipt" v-model="formGo.Tel" placeholder="请输入联系人电话"></el-input>
            </el-form-item>
            <el-form-item prop="appointMentDate" label="预约上门取件时间（选择三天后的时间）">
              <el-date-picker
                class="detail-ipt"
                style="width: 400px"
                type="datetimerange"
                v-model="formGo.appointMentDate"
                range-separator="至"
                start-placeholder="预约开始时间"
                end-placeholder="预约结束时间"
                clearable
                placeholder="datetime"
                align="left"
                :picker-options="pickerOptions"
                :default-time="['10:00:00', '18:00:00']"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="cerstyle">
        <el-button type="primary" class="title">证书领取方式</el-button>
        <div class="window-wrap">
          <p class="redPoint">温馨提示：</p>
          <p class="redPoint">
            1、选择“窗口领取”方式，您需要在不动产登记中心完成登记发证后，自行前往属地不动产登记中心窗口领取不动产权证书/证明。
          </p>
          <p class="redPoint">
            2、选择“EMS送件”方式，在不动产登记中心完成登记发证后，会由属地不动产登记中心通过邮政EMS邮寄的方式给您寄送不动产权证书/证明，相关邮寄费用由申请人自行承担。
          </p>
        </div>
        <el-radio v-model="radio1" label="1">窗口领取</el-radio>
        <el-radio v-model="radio1" label="2">EMS送件</el-radio>
        <div class="window" v-if="radio1 == 1">
          <p>收取办理材料地址:</p>
          <p style="margin-top: 20px">{{ localAddress }}</p>
        </div>
        <div class="window" v-else>
          <el-form :rules="rules" :model="formCame" ref="formCame" :label-position="right" label-width="160px">
            <el-form-item label="寄件大厅地址">
              {{ localAddress }}
            </el-form-item>
            <el-form-item prop="selectedOptions" label="收件人所在地">
              <el-cascader
                size="medium"
                :options="options"
                v-model="formCame.selectedOptions"
                @change="handleChange(1)"
              ></el-cascader>
            </el-form-item>
            <el-form-item prop="address1" label="收件人详细地址">
              <el-input class="detail-ipt" v-model="formCame.address1" placeholder="请输入详细地址"></el-input>
            </el-form-item>
            <el-form-item prop="userName1" label="联系人姓名">
              <el-input class="detail-ipt" v-model="formCame.userName1" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
            <el-form-item prop="Tel1" label="电话">
              <el-input class="detail-ipt" v-model="formCame.Tel1" placeholder="请输入联系人电话"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="main-footer text-center">
      <el-button type="primary" @click="prev()">上一步</el-button>
      <el-button type="primary" @click="next()">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import applicantEmsService from '@/pages/iebdc/api/apply/applicant-ems';
import { getCookie, removeCookie } from '@iebdc/utils/cookies.js';
import { get } from '@iebdc/utils/local-storage.js';
import { provinceAndCityData, regionData, CodeToText } from 'element-china-area-data';
import hall from '@iebdc/api/hall/hall-service';
import utils from '@iebdc/utils/utils';

export default {
  components: {
    // fileList,
  },
  props: {
    checkdisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // 手机号验证
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      bsdtData: [],
      isChecked: false,
      // 寄件
      receiverAddress: '',
      receiver_prov: '',
      receiver_city: '',
      receiver_county: '',
      // 收件
      senderAddress: '',
      sender_prov: '',
      sender_city: '',
      sender_county: '',
      radio: '1',
      radio1: '1',
      localAddress: '',
      options: regionData,
      address: '',
      userName: '',
      Tel: '',
      check: '', //是否可修改
      step: '', //申请步骤状态
      djjg: '',
      subCode: '',
      subFlowname: '',
      ewmImg: '',
      multiple: false, //多张上传
      fileData: [], //材料列表
      showNextTag: false, //默认不显示下一步按钮
      isApply: true, //是否申请中
      formGo: {
        address: '',
        selectedOptionsGo: [],
        userName: '',
        Tel: '',
        appointMentDate: '',
      },
      formCame: {
        address1: '',
        selectedOptions: [],
        userName1: '',
        Tel1: '',
      },
      rules: {
        selectedOptionsGo: [{ required: true, message: '必填', trigger: 'change' }],
        address: [{ required: true, message: '必填', trigger: 'blur' }],
        userName: [{ required: true, message: '必填', trigger: 'blur' }],
        Tel: [{ required: true, message: '必填', validator: validatePhone, trigger: 'change' }],
        appointMentDate: [{ required: true, message: '必填', trigger: 'change' }],
        selectedOptions: [{ required: true, message: '必填', trigger: 'change' }],
        address1: [{ required: true, message: '必填', trigger: 'blur' }],
        userName1: [{ required: true, message: '必填', trigger: 'blur' }],
        Tel1: [{ required: true, message: '必填', validator: validatePhone, trigger: 'change' }],
      },
      params: {
        nCurrent: 0,
        nSize: 10,
        djjg: '',
      }, //表格的查询参数
      pickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
    };
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    // ...mapGetters(['selectedBsdtCode']),
    ...mapState('wysq-data', ['flowInfoIng']),
  },
  created() {
    this.targetToStep('办理方式');
    // 获取cookie中的办事大厅
    this.params.djjg = getCookie('selectedAreaCode');
    this.formGo.userName = this.$decryptByDES(JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).name);
    this.formGo.Tel = this.$decryptByDES(JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).tel);
    this.formCame.userName1 = this.$decryptByDES(JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).name);
    this.formCame.Tel1 = this.$decryptByDES(JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).tel);
    this.userId = JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).userId;
    // 获取办事大厅数据
    this.getBsdtData();
  },
  mounted() {
    if (this.radio == 1 && this.radio1 == 1) {
      this.isChecked = true;
    }
  },
  methods: {
    // 更新流程信息
    ...mapMutations('wysq-data', ['setFlowInfoIng']),
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    handleChange(index) {
      var loc = '';
      if (index == 0) {
        for (let i = 0; i < this.formGo.selectedOptionsGo.length; i++) {
          loc += CodeToText[this.formGo.selectedOptionsGo[i]];
          this.sender_prov = CodeToText[this.formGo.selectedOptionsGo[0]];
          this.sender_city = CodeToText[this.formGo.selectedOptionsGo[1]];
          this.sender_county = CodeToText[this.formGo.selectedOptionsGo[2]];
        }
        this.senderAddress = loc; //寄件人地址
      }
      if (index == 1) {
        for (let i = 0; i < this.formCame.selectedOptions.length; i++) {
          loc += CodeToText[this.formCame.selectedOptions[i]];
          this.receiver_prov = CodeToText[this.formCame.selectedOptions[0]];
          this.receiver_city = CodeToText[this.formCame.selectedOptions[1]];
          this.receiver_county = CodeToText[this.formCame.selectedOptions[2]];
        }
        console.log(loc);
        this.receiverAddress = loc; //收件人地址
      }
    },
    // 跳转到上一页面
    prev() {
      this.targetToStep('办理方式');
      this.$router.push({
        name: this.prevItem.name,
        params: {
          ywh: this.flowInfoIng.wwywh,
        },
      });
    },
    // 如果选择邮寄服务 下单
    postOrder(index) {
      // 材料：寄件人sender--formGo 收件人receiver--大厅 证书：寄件人大厅 收件人formCame
      const params = {
        qlxxId: this.flowInfoIng.id,
        ywh: 'CL' + this.flowInfoIng.wwywh.replace(/[^\d]/g, ''),
        sendeId: JSON.parse(localStorage.getItem('IEBDC_USER_DATA')).userId,
        orderNo: 'CL' + this.flowInfoIng.wwywh.replace(/[^\d]/g, ''),
        startTime: utils.dateFtt(this.formGo.appointMentDate[0], 'yyyy-MM-dd hh:mm:ss'),
        endTime: utils.dateFtt(this.formGo.appointMentDate[1], 'yyyy-MM-dd hh:mm:ss'),
      };
      //
      let pickUpDto = {
        sender: {
          address: this.senderAddress + this.formGo.address,
          prov: this.sender_prov,
          city: this.sender_city,
          county: this.sender_county,
          mobile: this.formGo.Tel,
          name: this.formGo.userName,
          phone: this.formGo.Tel,
          post_code: '000000',
        },
        receiver: {
          address: this.receiverAddress + this.formCame.address1,
          prov: this.receiver_prov,
          city: this.receiver_city,
          county: this.receiver_county,
          mobile: this.formCame.Tel1,
          name: this.formCame.userName1,
          phone: this.formCame.Tel1,
          post_code: '000000',
        },
        cargos: [
          {
            cargo_name: '文件',
            cargo_category: '文件',
            // cargo_quantity:'1',
            // cargo_value:'100',
            // cargo_weight:'200',
          },
        ],
      };
      console.log(pickUpDto);
      const reg = /.+?(省|市|自治区|自治州|县|区)/g;
      // const parmasAddress = '新疆维吾尔自治区' + getCookie('selectedShiTitle') + getCookie('selectedAreaTitle');
      // const prov = parmasAddress.match(reg)[0];
      // const city = parmasAddress.match(reg)[1];
      // const county = parmasAddress.match(reg)[2];
      const prov = this.localAddress.match(reg)[0];
      const city = this.localAddress.match(reg)[1];
      const county = this.localAddress.match(reg)[2];
      const dtDataInfo = {
        address: this.localAddress,
        prov: prov,
        city: city,
        county: county,
        mobile: this.bsdtData.dh,
        name: getCookie('selectedBsdtTitle'),
        phone: this.bsdtData.dh,
        post_code: this.bsdtData.djjg,
      };
      // 材料邮寄 不走下单接口 仅保存参数
      if (index == 1) {
        params.ywh = 'CL-' + this.flowInfoIng.wwywh.replace(/[^\d]/g, '');
        params.spk = true;
        pickUpDto.receiver = dtDataInfo; //大厅数据
        if (this.checkTime()) {
          applicantEmsService
            .saveOrderInfo(params, pickUpDto)
            .then((res) => {
              if (res && res.success == true) {
                this.$message.success(res.message);
                this.jump();
              } else if (res.success == false) {
                this.$message.error(res.message);
              }
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        }
      } else if (index == 2) {
        // 证书收取 不走下单接口 仅保存参数
        params.ywh = 'ZS-' + this.flowInfoIng.wwywh.replace(/[^\d]/g, '');
        params.spk = false;
        pickUpDto.sender = dtDataInfo; //大厅数据
        applicantEmsService
          .saveOrderInfo(params, pickUpDto)
          .then((res) => {
            if (res && res.isSuccess == 'true') {
              this.$message.success('证书邮寄信息保存成功！');
              this.jump();
            } else if (res.isSuccess == 'false') {
              this.$message.error('证书邮寄信息保存成功！');
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    // 揽件时间验证
    checkTime() {
      const nowDateform0 = this.getTimeFormat(new Date()) + ' 00:00:00';
      const startHour = Number(utils.dateFtt(this.formGo.appointMentDate[0], 'yyyy-MM-dd hh:mm:ss').substr(11, 2));
      const endHour = Number(utils.dateFtt(this.formGo.appointMentDate[1], 'yyyy-MM-dd hh:mm:ss').substr(11, 2));
      if (startHour < 10 || endHour > 18) {
        this.$message.warning('请确定预约时间在工作时间内！(工作时间：10:00 - 18:00)');
        return false;
      } else if (this.formGo.appointMentDate[0].getTime() - new Date(nowDateform0).getTime() < 3 * 8.64e7) {
        this.$message.warning('请选择三天后的时间！');
        return false;
      } else {
        return true;
      }
    },
    getTimeFormat(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    },
    // 下一步 下单
    next() {
      if (this.radio == 1 && this.radio1 == 1) {
        this.jump();
      }
      if (this.radio == 2 && this.radio1 == 2) {
        this.$refs.formGo.validate((valid) => {
          if (valid) {
            this.$refs.formCame.validate((valid) => {
              if (valid) {
                this.postOrder(1);
                this.postOrder(2);
              }
            });
          }
        });
      }
      if (this.radio == 2 && this.radio1 == 1) {
        this.$refs.formGo.validate((valid) => {
          if (valid) {
            this.postOrder(1);
          }
        });
      }
      if (this.radio == 1 && this.radio1 == 2) {
        this.$refs.formCame.validate((valid) => {
          if (valid) {
            this.postOrder(2);
          }
        });
      }
    },
    // 跳转到下一页面 需先校验当前页面数据状态
    jump() {
      if (!this.flowInfoIng.wwywh) return;
      this.targetToStep('办理方式');
      // if (this.step === 'VERIFYSUCCESS' || !this.$hasPermission('IEBDC:SQ:SQRZ')) {
      //   this.$router.push({
      //     name: this.nextItem.name,
      //   });
      // } else {
      //   this.$message.warning('请先返回上一步完成身份认证！');
      // }
      this.$router.push({
        name: this.nextItem.name,
      });
      // } else {
      //   this.$message.warning('请先返回上一步完成身份认证！');
      // }
    },
    // 获取办事大厅地址 电话 等
    getBsdtData() {
      hall.query(this.params).then((res) => {
        if (res.success === true) {
          this.bsdtData = res.data.page[0];
          // this.localAddress = '新疆维吾尔自治区' + this.bsdtData.address;
          this.localAddress = this.bsdtData.address;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dealDisabledDate(time) {
      if (new Date(time).getTime() < new Date().getTime()) {
        return time.getTime() < Date.now() + 3 * 8.64e7;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  .content {
    display: block;
    width: 1148px;
    border: 1px solid #ccc;
    .title {
      margin-right: 20px;
    }
    .window {
      padding: 20px 0;
    }
    .matstyle {
      width: 100%;
    }
    .cerstyle {
      width: 100%;
      margin-top: 50px;
    }
    /deep/ .el-input__inner {
      width: 420px !important;
    }
    /deep/ .el-button--small {
      padding: 9px 28px;
      font-size: 12px;
      border-radius: 3px;
    }
  }
}
.window-wrap {
  margin: 20px 0;
  line-height: 20px;
}
.redPoint {
  color: red;
  margin-top: 3px;
  font-size: 14px;
  margin-left: 10px;
}
</style>
