import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantEmsService = {
  // 上门揽件
  getPickUp(params) {
    return request({
      url: REST_SERVICE.ems.pickUp,
      method: 'get',
      params,
    });
  },
  // 下单
  createOrder(params, data) {
    return request({
      url: REST_SERVICE.ems.createOrder,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
      data: data,
    });
  },
  // 获取用户的邮寄列表
  getOrdersByUserId(params) {
    return request({
      url: REST_SERVICE.ems.getOrders,
      method: 'get',
      params: params,
    });
  },
  // 揽收结果
  getEmsResult(params) {
    return request({
      url: REST_SERVICE.ems.getEmsResult,
      method: 'get',
      params: params,
    });
  },
  // 取消订单
  cancelOrder(params) {
    return request({
      url: REST_SERVICE.ems.cancelOrder,
      method: 'get',
      params: params,
    });
  },
  // 取消上门揽件
  cancelPickUp(params) {
    return request({
      url: REST_SERVICE.ems.cancelPickUp,
      method: 'get',
      params: params,
    });
  },
  // 修改预约时间
  changeTime(params) {
    return request({
      url: REST_SERVICE.ems.changeTime,
      method: 'get',
      params: params,
    });
  },
  // 获取轨迹
  getTrace(params) {
    return request({
      url: REST_SERVICE.ems.getTrace,
      method: 'get',
      params: params,
    });
  },
  // 保存参数
  saveOrderInfo(params, data) {
    return request({
      url: REST_SERVICE.ems.saveOrderInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
      data,
    });
  },
};

export default applicantEmsService;
